import React from "react";
import { Helmet } from 'react-helmet';

import ContactForm from "../components/contactForm";
import ReviewsBanner from "../components/reviewsBanner";

function Bathroom() {
    return (
        <>
            <Helmet>
                <title>Bathroom</title>
                <meta name="description" content="Transform your bathroom with Top Line Interiors Kitchen, Bath & Flooring. We specialize in seamless, tailored remodeling with expert craftsmanship. From elegant fixtures to modern designs, we create beautiful, functional spaces in Woodland, CA." />
            </Helmet>
            <div className="bathroom border-b-4 border-b-dd-brown pb-10">
                <div className="max-w-7xl m-auto pt-40 pb-16">
                    <div className="text-white text-center">
                        <div className="md:text-6xl text-5xl font-bold drop-shadow-md">
                            Bathroom Services
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl grid md:grid-cols-2 gap-10 pt-24 pb-16 px-4 m-auto">
                <div className="">
                    <div className="text-6xl text-center font-semibold">
                        Remodeling Services
                    </div>
                    <div className="pt-10">
Embark on a journey of transformation with Top Line Interiors Kitchen, Bath & Flooring, where we redefine the art of bathroom remodeling. Our expertise turns the often daunting task of renovating your bathroom into a seamless and enjoyable experience.
<br/><br/>
Tailored Renovation Approach:
<br/><br/>
We understand that bathroom remodeling is more than a project; it's about creating a personal sanctuary. Our approach is centered around your vision and needs. From the initial design to the final touches, our dedicated general contractors are there to guide you every step of the way. We bring a blend of creativity, expertise, and meticulous attention to detail to ensure your bathroom renovation is not just completed, but masterfully executed.
<br/><br/>
A Spectrum of High-Quality Products and Services:
<br/><br/>
Whether you're dreaming of a spa-like retreat or a sleek, modern washroom, our extensive range of products caters to every style and functionality. Our offerings include elegant pedestal sinks, luxurious fixtures, and innovative storage solutions. Beyond installations, our expertise in color-matching and resurfacing allows us to craft a bathroom that is not only functional but also a true reflection of your personal style.
<br/><br/>
Expertise in Comprehensive Bathroom Solutions:
<br/><br/>
Our team of skilled bathroom remodelers and contractors brings a wealth of experience in both home and commercial renovations. We understand that each space has its unique challenges and possibilities, and we are adept at navigating these to deliver outstanding results. Whether it's a simple update or a complete overhaul, we are committed to excellence in every aspect of our service.
<br/><br/>
For your next bathroom remodel, choose Top Line Interiors Kitchen, Bath & Flooring in Woodland, CA. We promise not just to meet your expectations but to exceed them, transforming your bathroom into a space that combines beauty, functionality, and comfort. Our team is ready to bring your dream bathroom to life, ensuring a smooth, efficient, and satisfying renovation experience.

                    </div>
                </div>
                <div className="md:p-10">
                    <ContactForm bgColor="bg-gray-100" headerText="Create Your Dream Bathroom - Contact Us for a Remodel Consultation!"/>
                </div>
            </div>
            <div className="pb-16">
                <div className="max-w-7xl m-auto grid md:grid-cols-4 gap-4 mt-16 px-5">
                    <ReviewsBanner/>
                </div>
            </div>
        </>
    );
}

export default Bathroom;
