import React from "react";
import { Helmet } from 'react-helmet';

import ContactForm from "../components/contactForm";
import ReviewsBanner from "../components/reviewsBanner";

function Remodeling() {
    return (
        <>
            <Helmet>
                <title>Remodeling</title>
                <meta name="description" content="Top Line Interiors Kitchen, Bath & Flooring in Woodland, CA, specializes in custom kitchen and bathroom remodeling. We create beautiful, functional spaces with bespoke cabinets, stunning countertops, and luxurious bathroom designs, all crafted with precision and high-quality materials." />
            </Helmet>
            <div className="hero border-b-4 border-b-dd-brown pb-10">
                <div className="max-w-7xl m-auto pt-40 pb-16">
                    <div className="text-white text-center">
                        <div className="md:text-6xl text-5xl font-bold drop-shadow-md">
                            Remodeling Services
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl grid md:grid-cols-2 gap-10 pt-24 pb-16 px-4 m-auto">
                <div className="">
                    <div className="text-6xl text-center font-semibold">
                        Remodeling Services
                    </div>
                    <div className="pt-10">
Welcome to Top Line Interiors Kitchen, Bath & Flooring, where your remodeling dreams become reality. Our expertise in home transformations is not just a service, but a commitment to excellence and craftsmanship.
<br/><br/>
Custom Kitchen Cabinets and Countertops:
<br/><br/>
At the heart of every home is the kitchen, a place of warmth, family, and culinary creativity. Our custom kitchen remodeling services are designed to breathe new life into this vital space. We specialize in installing bespoke cabinets that blend functionality with aesthetic elegance. Alongside, our range of stunning countertops, crafted from the finest materials, will add a touch of sophistication and durability to your kitchen. Whether you're envisioning a modern, sleek design or a classic, cozy feel, our team will bring your vision to life with precision and flair.
<br/><br/>
Bathroom Remodeling:
<br/><br/>
Transform your bathroom into a personal oasis with Top Line Interiors Kitchen, Bath & Flooring. Our bathroom remodeling services focus on creating a space that is both luxurious and practical. From elegant fixtures to efficient layouts, we ensure every detail contributes to a harmonious and relaxing environment. Our team takes pride in delivering high-quality workmanship, ensuring your new bathroom is not only beautiful but also built to last.
<br/><br/>
At Top Line Interiors Kitchen, Bath & Flooring, we understand that remodeling is more than just a project; it's about creating spaces that reflect your style and meet your needs. Our collaborative approach means we work closely with you every step of the way, ensuring your remodeling journey is seamless and satisfying.
<br/><br/>
Choose us for your next remodeling project, and experience the transformation of your home into the sanctuary you've always desired.
                    </div>
                </div>
                <div className="md:p-10">
                    <ContactForm bgColor="bg-gray-100" headerText="Ready to Reimagine Your Space? Contact Us for Expert Remodeling."/>
                </div>
            </div>
            <div className="pb-16">
                <div className="max-w-7xl m-auto grid md:grid-cols-4 gap-4 mt-16 px-5">
                    <ReviewsBanner/>
                </div>
            </div>
        </>
    );
}

export default Remodeling;
